import React from 'react';
import { UploadingContainer } from './file-upload.styled';
import { MdAttachFile } from 'react-icons/md';
import EducrackAPI from '@lipihipi/client-sdk';
import { IconButton } from '@lipihipi/ec-ui';
import { AiFillCloseCircle } from 'react-icons/ai';

export const DocumentPreview = ({ file, onDelete, target = '' }: any) => {
  return (
    <UploadingContainer>
      <MdAttachFile className={'mr-12pt'} />
      <a
        className="card-title w-auto"
        href={EducrackAPI.asset.getAssetUrl(file)}
        target={target} //send target="_blank" to open in new tab
        //onClick={()=>window.open(EducrackAPI.asset.getAssetUrl(file))}
      >
        {file.split('.').pop()}
      </a>
      <IconButton onClick={() => onDelete(file)}>
        <AiFillCloseCircle />
      </IconButton>
    </UploadingContainer>
  );
};
