import React, { useEffect, useState } from 'react';
import { PageHeader, PaginatedTable, Loader } from '@lipihipi/ec-ui';
import { DateTime, Form, Input, Select } from '@lipihipi/form';
import { MdSearch } from 'react-icons/md';
import { Avatar, mapOptions } from '../test/list';
import Swal from 'sweetalert2';
import moment from 'moment';

export const commonApiError = (error: any, history?: any, url?: string) => {
  Swal.fire({
    title: 'Error',
    text: error?.message || 'Something went wrong, please try after some time.',
    icon: 'error',
  }).then(() => {
    if (url) {
      history.push('/dashboard');
    }
  });
};

const InterviewResultList = ({
  breadCrumbs,
  getCourses,
  getInterviewList,
  getAssetUrl,
  onInterviewReportClick,
  getCenters,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState<any>({
    populate: true,
    page: 1,
    isRecent: true,
  });
  const [interviewList, setInterviewList] = useState({
    totalItems: 0,
    interviews: [],
  });
  const [courses, setCourses] = useState<any>({
    totalItems: 0,
    courses: [],
  });

  const [centers, setCenters] = React.useState<any>({
    centers: [],
    totalItems: 0,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getInterviewList(params);
        setInterviewList(data);
        setIsLoading(false);
      } catch (error) {
        console.log('error', error);
        commonApiError(error);
      }
    };
    getCourses({ populate: true, all: true }).then(({ data }: any) => {
      setIsLoading(false);
      setCourses(data);
    });
    getData();
  }, [params]);

  useEffect(() => {
    getCenters()
      .then(({ data }: any) => {
        setCenters((oldData: any) => ({
          ...oldData,
          centers: data.centers,
        }));
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  const handleSearch = (values: any) => {
    if (values?.from) {
      values.from = moment(values?.from)
        .utcOffset('+05:30')
        .format('YYYY-MM-DD');
    }
    if (values?.to) {
      values.to = moment(values?.to)
        .utcOffset('+05:30')
        .format('YYYY-MM-DD');
    }
    setParams({
      ...values,
      isRecent: true,
      page: 1,
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="main-structure">
        <PageHeader
          title="IntAna"
          breadCrumbs={
            breadCrumbs || [
              { title: 'Reports', link: '/' },
              { title: 'IntAna' },
            ]
          }
        />

        <Form
          initialValues={params}
          onSubmit={handleSearch}
          render={({ submitForm }: any) => (
            <div className="row">
              <div className="col-md-6">
                <Input
                  prefix={<MdSearch />}
                  id="searchExam"
                  name="q"
                  placeholder="Enter interview name to search"
                />
              </div>
              <div className="col-md-4">
                <Select
                  id="course"
                  name="course"
                  placeholder="Select Course"
                  onChange={submitForm}
                  options={[
                    { label: 'All', value: '' },
                    ...mapOptions(courses.courses),
                  ]}
                />
              </div>

              <div className="col-md-3 d-none">
                <Select
                  id="exam"
                  name="exam"
                  onChange={submitForm}
                  placeholder="Select Status"
                  options={[{ label: 'All', value: '' }]}
                />
              </div>
              <div className="col-md-4">
                <Select
                  id="center"
                  name="center"
                  placeholder="Select Center"
                  onChange={submitForm}
                  options={[
                    { label: 'ALL', value: '' },
                    ...mapOptions(centers.centers),
                  ]}
                />
              </div>
              <div className="col-md-3">
                <DateTime
                  name="from"
                  id="from"
                  label=""
                  dateFormat="MMMM d, yyyy"
                  onChange={submitForm}
                  placeholderText="Start Date"
                  isClearable
                />
              </div>
              <div className="col-md-3">
                <DateTime
                  name="to"
                  id="to"
                  label=""
                  placeholderText="End Date"
                  dateFormat="MMMM d, yyyy"
                  onChange={submitForm}
                  isClearable
                />
              </div>
              <button type="submit" className="d-none">
                Search
              </button>
            </div>
          )}
        />
        <PaginatedTable
          onPageChange={page => {
            setParams({ ...params, page });
          }}
          totalItems={interviewList.totalItems}
          currentPage={params.page || 1}
          itemsPerPage={10}
          data={interviewList.interviews}
          columns={[
            {
              dataRenderer: (data: any) => (
                <div className="avtar-with-text cursor-pointer">
                  <Avatar
                    className="primary-avtar"
                    url={
                      data?.displayPicture
                        ? getAssetUrl(data?.displayPicture)
                        : ''
                    }
                  />
                  <div className="wrap">
                    <p className="avtar-name m-0">{data?.name}</p>
                  </div>
                </div>
              ),
              title: 'IntAna Name',
              width: 'calc(100% - (200px + 200px + 200px + 102px))',
            },
            {
              dataRenderer: (data: any) => (
                <div className="primary-text">
                  {data?.course?.name ? data?.course?.name : 'Not Available'}
                </div>
              ),
              title: 'Course',
              width: '200px',
            },
            {
              dataRenderer: (data: any) => (
                <div className="primary-text">{data?.userInterviewCount}</div>
              ),
              title: 'No of Candidates',
              width: '200px',
            },
            {
              dataRenderer: (data: any) => (
                <div className="primary-text">
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onInterviewReportClick(data?._id);
                    }}
                  >
                    View Details
                  </a>
                </div>
              ),
              title: '',
              width: '102px',
            },
          ]}
        />
      </section>
    </>
  );
};

export default InterviewResultList;
