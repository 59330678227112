import React, { useEffect, useState } from 'react';
import { PageHeader, Tab, Tabs } from '@lipihipi/ec-ui';
import Styled from 'styled-components';
import { IStudentDetailProps } from './subscriber-report.types';
import SubscriberBatches from './components/subscriberBatches';
import SubscriberTests from './components/subscriberTests';
import SubscriberInterview from './components/subscriberInterview';
import SubscriberPyschometricTests from './components/subscriberPyschometricTest';
import swal from 'sweetalert';

export const Avatar = Styled.div<{ url: string }>`
	background-image: url(${({ url }: any) => url});
`;

export const commonApiError = (error: any, history?: any, url?: string) => {
  swal({
    title: 'Error',
    text: error?.message || 'Something went wrong, please try after some time.',
    icon: 'error',
  }).then(() => {
    if (url) {
      history.push('/dashboard');
    }
  });
};
const SubscriberReport = ({
  _id,
  getBatches,
  getAssetUrl,
  getStudent,
  breadCrumbs,
  getTests,
  getInterviews,
  onBatchTestClick,
  onTestBundleClick,
  onInterviewClick,
  getUserTest,
  onReportClick,
  onPsychometricReportClick,
}: IStudentDetailProps) => {
  const [student, setStudent] = useState<any>({});
  const [state, setState] = useState<any>('batches');
  useEffect(() => {
    if (_id && getStudent) {
      getStudent(_id, { populate: true })
        .then(({ data }: any) => {
          setStudent(data);
        })
        .catch((error: any) => {
          commonApiError(error);
        });
    }
  }, []);

  useEffect(() => {
    setState('batches');
  }, []);
  return (
    <>
      <section className="main-structure">
        <PageHeader
          title={student?.name || 'Candidate Name'}
          description={student?.email || 'Candidate email'}
          breadCrumbs={
            breadCrumbs || [
              { title: 'Home', link: '/dashboard' },
              { title: 'Manage Candidate', link: '/manage-students' },
              { title: 'Candidates Report' },
            ]
          }
          component={
            <Avatar
              className="primary-avtar"
              url={
                student?.displayPicture
                  ? getAssetUrl(student?.displayPicture)
                  : getAssetUrl('asset-1621852368169.jpg')
              }
            />
          }
        />
        <Tabs active={state} onClick={setState}>
          <Tab id={'batches'} title={'Programs'}>
            <SubscriberBatches
              getBatches={getBatches}
              _id={_id}
              onBatchTestClick={onBatchTestClick}
            />
          </Tab>
          <Tab id={'test'} title={'Test(ATT)'}>
            <SubscriberTests
              getTests={getTests}
              _id={_id}
              onTestBundleClick={onTestBundleClick}
              getUserTest={getUserTest}
              studentId={student?._id}
              onReportClick={onReportClick}
            />
          </Tab>
          <Tab id={'interview'} title={'IntAna'}>
            <SubscriberInterview
              getInterviews={getInterviews}
              _id={_id}
              onInterviewClick={onInterviewClick}
            />
          </Tab>
          <Tab id={'psychometric'} title={'Psychometric Test'}>
            <SubscriberPyschometricTests _id={_id} onPsychometricReportClick={onPsychometricReportClick} />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default SubscriberReport;
