import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
  TCreateResource,
} from './types';
import { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { EnvironmentService } from './env.service';

export interface IAssetListParams extends IListParams {
  addedBy?: ObjectId;
}

export interface IAssetUpload {
  file: File;
  name?: string;
}

export interface IAsset {
  name: string;
  mimeType: string;
  size: Number;
  key: string;
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IAssetDocument extends IAsset, IDocument {}

export interface IAssetListResponse {
  totalItems: number;
  assets: IAssetDocument[];
}
export interface IPrivateAssetResponse {
  url: string;
}

const get = (id: string) => {
  return client.get<IAssetDocument>(`/assets/${id}`);
};
let cancelRequest: any;
const cancel = () => {
  if (cancelRequest) {
    cancelRequest();
  }
};
const create: TCreateResource<IAssetUpload, IAssetDocument> = (
  asset: IAssetUpload,
  options?: AxiosRequestConfig
) => {
  const formData = new FormData();
  formData.append('file', asset.file);
  if (asset.name) {
    formData.append('name', asset.name);
  }
  const CancelToken = axios.CancelToken;
  return client.post<IAssetDocument>(`/assets`, formData, {
    ...options,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancelRequest = c;
    }),
  });
};
const createPrivate: TCreateResource<IAssetUpload, IAssetDocument> = (
  asset: IAssetUpload,
  options?: AxiosRequestConfig
) => {
  const formData = new FormData();
  formData.append('file', asset.file);
  if (asset.name) {
    formData.append('name', asset.name);
  }
  const CancelToken = axios.CancelToken;
  return client.post<IAssetDocument>(`/assets/private`, formData, {
    ...options,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancelRequest = c;
    }),
  });
};
const update = (asset: IAssetDocument) => {
  return client.put<IAssetDocument>(`/assets/${asset._id}`, asset);
};

const remove = (id: string) => {
  return client.delete(`/assets/${id}`);
};

const list = (params: IAssetListParams) => {
  return client.get<IAssetListResponse>(`/assets/`, { params });
};

const getAssetUrl = (key?: string) => {
  const config = EnvironmentService.getConfig();
  let url = 'https://educrack-dev.s3.ap-south-1.amazonaws.com/';
  if (config?.bucketUrl) {
    url = config.bucketUrl;
  }
  if (key) {
    url = url + key;
  }
  return url;
};

const getPrivateAssetUrl = (id: string) => {
  return client.get<IPrivateAssetResponse>(`/assets/private/${id}`, {});
};

export const asset = {
  get,
  getAssetUrl,
  create,
  update,
  remove,
  list,
  cancel,
  createPrivate,
  getPrivateAssetUrl,
};
