import React, { useEffect, useState } from 'react';
import { Button, PageHeader } from '@lipihipi/ec-ui';
import { Input, Form, Select } from '@lipihipi/form';
import { IGroupListProps } from '../subscriber/subscriber-list.types';
import swal from 'sweetalert';
import { FormSchema } from './user-form.schema';
import { commonApiError } from './subscriber-report';

const mapOptionsExam = (values: any[]) => {
  return values?.length
    ? values.map(value => ({ label: value.name, value: value._id }))
    : [];
};
const mapOptions = (values: any[]) => {
  return values.map(value => ({ label: value.name, value: value._id }));
};

export const AddStudent = ({
  _id,
  getStudentProfile,
  updateStudentProfile,
  getGroup,
  getCourses,
  create,
  afterStudentCraeted,
  title,
  breadCrumbs,
}: IGroupListProps) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    group: '',
    courses: [],
    gender: '',
    selectedCourses: [],
  });

  const [userGroup, setUserGroup] = useState<any>({
    totalItems: 0,
    groups: [],
  });

  const [courses, setCourses] = useState<any>({
    totalItems: 0,
    courses: [],
  });

  useEffect(() => {
    _getUserGroup();
    _getCourses();
  }, []);

  useEffect(() => {
    if (_id) {
      _getStudentProfile();
    }
  }, [_id]);

  const _getStudentProfile = () => {
    getStudentProfile(_id, { all: true, populate: true })
      .then(({ data }: any) => {
        const courses = data?.selectedCourses?.map((item: any) => {
          return item?.course?._id;
        });

        setState({
          name: data?.name,
          email: data?.email,
          group: data?.group?._id,
          courses: courses,
          gender: data.gender,
          selectedCourses: data?.selectedCourses,
        });
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  const _getUserGroup = () => {
    getGroup({ all: true })
      .then(response => {
        setUserGroup(response.data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  const _getCourses = () => {
    getCourses({ all: true })
      .then((response: any) => {
        setCourses(response.data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  };

  const handleSubmit = (values: any) => {
    const selectedCourses = values.courses.map((course: any) => {
      return {
        course: course,
        isActive: true,
      };
    });
    values.selectedCourses = [...selectedCourses];
    values.role = 'user';
    values.courses = undefined;
    values.group = values.group || null;

    if (_id) {
      updateStudentProfile(_id, values)
        .then(({ status }: any) => {
          if (status === 200) {
            swal({
              title: 'Success',
              text: 'Candidate Created',
              icon: 'success',
              dangerMode: false,
            }).then(afterStudentCraeted);
          } else {
            swal({
              title: 'Error',
              text: 'Server Error!',
              icon: 'error',
              dangerMode: true,
            });
          }
        })
        .catch((error: any) => {
          commonApiError(error);
        });
    } else {
      create(values)
        .then(({ status }: any) => {
          if (status === 200) {
            swal({
              title: 'Success',
              text: 'Candidate Created',
              icon: 'success',
              dangerMode: false,
            }).then(afterStudentCraeted);
          } else {
            swal({
              title: 'Error',
              text: 'Server Error!',
              icon: 'error',
              dangerMode: true,
            });
          }
        })
        .catch((error: any) => {
          commonApiError(error);
        });
    }
  };

  return (
    <section className="main-structure">
      <PageHeader
        title={title || `${_id ? 'Edit' : 'Add'} a Candidate`}
        breadCrumbs={
          breadCrumbs || [
            { title: 'Home', link: '/dashboard' },
            { title: 'Manage Candidate', link: '/manage-students' },
            { title: `${_id ? 'Edit' : 'Add'} a Candidate`, link: '/' },
          ]
        }
      />

      <Form
        initialValues={state}
        onSubmit={handleSubmit}
        validationSchema={FormSchema}
      >
        <div className="row">
          <div className="col-md-12">
            <Input
              id="name"
              name="name"
              type="text"
              label="Name"
              required={true}
              placeholder="Enter Name..."
            />
          </div>

          <div className="col-md-6">
            <Input
              id="email"
              name="email"
              type="email"
              label="Email ID"
              required={true}
              placeholder="Enter Email Id..."
            />
          </div>

          <div className="col-md-6">
            <Input
              id="mobile"
              name="mobile"
              type="mobile"
              label="Mobile"
              required={true}
              placeholder="Enter mobile number..."
            />
          </div>

          <div className="col-md-6">
            <Select
              id="group"
              name="group"
              label="User Group"
              placeholder={'Select User Group'}
              options={[...mapOptionsExam(userGroup.groups)]}
            />
          </div>

          <div className="col-md-6">
            <Select
              id="courses"
              name="courses"
              label="Courses"
              placeholder={'Courses'}
              isMulti
              options={[...mapOptions(courses.courses)]}
            />
          </div>

          <div className="col-md-6">
            <Select
              id="gender"
              name="gender"
              label="Gender"
              placeholder={'Gender'}
              options={[
                { label: 'Male', value: 'MALE' },
                { label: 'Female', value: 'FEMALE' },
              ]}
            />
          </div>
        </div>

        <div className="form-group d-flex mt-5">
          <Button className="px-5" shape="primary" type="submit">
            SAVE
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default AddStudent;
