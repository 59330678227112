import React, { useCallback } from 'react';
import {
  PageHeader,
  PaginatedTable,
  ActionButton,
  ListItemAction,
  Menu,
  Loader,
} from '@lipihipi/ec-ui';
import { DateTime, Form, Input, Select } from '@lipihipi/form';
import moment from 'moment';
import { MdSearch } from 'react-icons/md';
import Styled from 'styled-components';
import { IStudentListProps } from './types';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { commonApiError } from '../interview/list';

export const Avatar = Styled.div<{ url: string }>`
background-image: url(${({ url }: any) => url});
`;

const mapSelectValues = (values: any[]) => {
  return values.map(value => {
    return { label: value.name, value: value._id };
  });
};

const StudentList = ({
  getStudents,
  title,
  breadCrumbs,
  getAssetUrl,
  onStudentReportClick,
  getCourses,
  getCenters,
  report,
}: IStudentListProps) => {
  const [students, setStudents] = React.useState<any>({
    totalItems: 0,
    users: [],
  });

  const [downloadStudents, setDownloadStudents] = React.useState<any>({
    totalItems: 0,
    users: [],
  });

  const [allStudents, setAllStudents] = React.useState<any>([]);

  const [params, setParams] = React.useState<any>({
    populate: true,
    page: 1,
    q: '',
  });

  const [downloadParams, setDownloadParams] = React.useState<any>({
    all: true,
    q: '',
  });

  const [courses, setCourses] = React.useState<any>({
    courses: [],
    totalItems: 0,
  });

  const [centers, setCenters] = React.useState<any>({
    centers: [],
    totalItems: 0,
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const fetchData = async (urlParams: any) => {
    setIsLoading(true);
    try {
      const { data: list }: any = await getStudents({ ...urlParams });
      const { data }: any = await report({ ...urlParams });
      const updatedList = list?.users.map((v: any) => {
        const current = data?.users?.find((user: any) => user._id === v._id);
        const avgIntanna = current?.avgIntanna || 0;
        const avgTestScore = current?.avgTestScore || 0;
        const allAvg = (avgIntanna + avgTestScore) / 2;
        return {
          ...v,
          ...current,
          avgIntanna: parseFloat(avgIntanna.toFixed(2)),
          avgTestScore: parseFloat(avgTestScore.toFixed(2)),
          allAvg: parseFloat(allAvg.toFixed(2))
        };
      });
      setIsLoading(false);
      return { ...list, users: updatedList };
    } catch (error) {
      commonApiError(error);
    }
  };

  React.useEffect(() => {
    const fetchDataAndUpdateStudents = async () => {
      try {
        const data = await fetchData(params);
        setStudents(data);
      } catch (error) {
        // Handle error
        commonApiError(error);
      }
    };
    fetchDataAndUpdateStudents();
  }, [params]);

  React.useEffect(() => {
    const fetchDataAndUpdateStudents = async () => {
      try {
        const data = await fetchData(downloadParams);
        setDownloadStudents(data);
      } catch (error) {
        // Handle error
        commonApiError(error);
      }
    };
    fetchDataAndUpdateStudents();
  }, [downloadParams]);

  React.useEffect(() => {
    getCourses({ ...params })
      .then(({ data }: any) => {
        setCourses(data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
      const fetchDataAndUpdateStudents = async () => {
        try {
          const data = await fetchData(downloadParams);
          setAllStudents(data);
        } catch (error) {
          // Handle error
          commonApiError(error);
        }
      };
      fetchDataAndUpdateStudents();
    getCenters()
      .then(({ data }: any) => {
        setCenters((oldData: any) => ({
          ...oldData,
          centers: data.centers,
        }));
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  const handleSearch = (values: any) => {
    if (values?.from) {
      values.from = moment(values?.from)
        .utcOffset('+05:30')
        .format('YYYY-MM-DD');
    }
    if (values?.to) {
      values.to = moment(values?.to)
        .utcOffset('+05:30')
        .format('YYYY-MM-DD');
    }
    setParams({
      ...values,
      page: 1,
    });
    setDownloadParams({
      ...values,
    });
  };

  const exportToExcel = useCallback((downloaded, all) => {
    let studentsData;
    if (params?.startDate || params?.endDate || params?.q || params?.course) {
      studentsData = downloaded?.users;
    } else {
      studentsData = all?.users;
    }

    const excelData: any = [];
    if (studentsData?.length > 0) {
      studentsData.forEach((student: any) => {
        const studentDetails = {
          'Candidate Name': student.name ? student.name : 'Not Available',
          'Candidate Email': student.email ? student.email : 'Not Available',
          'Candidate Mobile': student.mobile ? student.mobile : 'Not Available',
          'AI-IntAna': student.avgIntanna,
          'ATT': student.avgTestScore,
          'Average': student.allAvg,
        };
        excelData.push(studentDetails);
      });
    }

    return excelData;
  }, [downloadStudents, allStudents]);

  return (
    <>
      {isLoading && <Loader />}
      <section className="main-structure">
        <PageHeader
          title={title || 'Candidates Reports'}
          breadCrumbs={
            breadCrumbs || [
              { title: 'Reports', link: '/' },
              { title: 'Candidates Report' },
            ]
          }
        />

        <Form
          initialValues={params}
          onSubmit={handleSearch}
          render={({ submitForm }: any) => (
            <>
              <div style={{ float: 'right' }}>
                <Button
                  name="Export"
                  id="Export"
                  onClick={() => exportToExcel(downloadStudents, allStudents)}
                >
                  <CSVLink
                    data={exportToExcel(downloadStudents, allStudents)}
                    filename={'Candidate Reports.csv'}
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </Button>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <Input
                    prefix={<MdSearch />}
                    id="searchExam"
                    name="q"
                    onChange={submitForm}
                    placeholder="Enter here to search for Candidates"
                  />
                </div>
                <div className="col-md-4">
                  <Select
                    id="course"
                    name="course"
                    placeholder="Select Course"
                    onChange={submitForm}
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapSelectValues(courses.courses),
                    ]}
                  />
                </div>
                <div className="col-md-4">
                  <Select
                    id="center"
                    name="center"
                    placeholder="Select Center"
                    onChange={submitForm}
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapSelectValues(centers.centers),
                    ]}
                  />
                </div>
                <div className="col-md-3">
                  <DateTime
                    name="from"
                    id="from"
                    label=""
                    dateFormat="MMMM d, yyyy"
                    onChange={submitForm}
                    placeholderText="Start Date"
                    isClearable
                  />
                </div>
                <div className="col-md-3">
                  <DateTime
                    name="to"
                    id="to"
                    label=""
                    placeholderText="End Date"
                    dateFormat="MMMM d, yyyy"
                    onChange={submitForm}
                    isClearable
                  />
                </div>
                <button type="submit" className="d-none"></button>
              </div>
            </>
          )}
        />

        <PaginatedTable
          data={students.users}
          columns={[
            {
              dataRenderer: (data: any) => (
                <div className="avtar-with-text cursor-pointer">
                  <Avatar
                    className="primary-avtar"
                    url={
                      data?.displayPicture
                        ? getAssetUrl(data?.displayPicture)
                        : ''
                    }
                  />
                  <a style={{ fontSize: '14px' }}>
                    <div className="wrap">
                      <p className="avtar-name">
                        {data?.name ? data?.name : 'Not Available'}
                        {`(${data.eduId})`}
                      </p>
                      <p className="avtar-email m-0">
                        {data?.email ? data?.email : 'Not Available'}
                      </p>
                      <p className="avtar-mobile m-0">
                        {data?.mobile ? data?.mobile : 'Not Available'}
                      </p>
                    </div>
                  </a>
                </div>
              ),
              title: 'Candidate Name',
              width: 'calc(100% - (300px + 60px ) )',
            },
            {
              dataRenderer: (data: any) => <div className="primary-text">{data?.avgIntanna}</div>,
              title: 'AI-IntAna',
              width: '100px',
            },
            {
              dataRenderer: (data: any) => <div className="primary-text">{data?.avgTestScore}</div>,
              title: 'ATT',
              width: '100px',
            },
            {
              dataRenderer: (data: any) => <div className="primary-text">{data?.allAvg}</div>,
              title: 'Average',
              width: '100px',
            },
            // {
            //   dataRenderer: (data: any) => (
            //     <div className="primary-text">
            //       {data?.selectedCourses?.length
            //         ? data?.selectedCourses
            //             ?.map((selectedCourses: any) => {
            //               return selectedCourses.course.name;
            //             })
            //             .join(', ')
            //         : 'Not Available'}
            //     </div>
            //   ),
            //   title: 'Course(s)',
            //   width: '200px',
            // },
            // {
            //   dataRenderer: (data: any) => (
            //     <div className="primary-text">
            //       {moment(data?.createdAt).format('D MMM YYYY')}
            //     </div>
            //   ),
            //   title: 'Signup Date',
            //   width: '250px',
            // },
            {
              dataRenderer: (data: any) => (
                <ListItemAction>
                  <ActionButton>
                    <BsThreeDotsVertical />
                  </ActionButton>
                  <Menu>
                    <li
                      onClick={() => {
                        onStudentReportClick(data?._id);
                      }}
                    >
                      View Report
                    </li>
                  </Menu>
                </ListItemAction>
              ),
              title: '',
              width: '60px',
            },
          ]}
          totalItems={students?.totalItems}
          onPageChange={page => {
            setParams({ ...params, page });
          }}
          itemsPerPage={10}
          currentPage={params.page || 1}
        />
      </section>
    </>
  );
};

export default StudentList;
