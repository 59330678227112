import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { Label } from '../label/label';
import { Input } from '../input/input';
import { ReactAutosuggestWrapper } from './auto-suggest.styled';
import { IAutoSuggestProps } from './auto-suggest.types';

const getSuggestions = (value: any, suggestionList: any) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : suggestionList.filter(
        (item: any) =>
          item.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const getSuggestionValue = (suggestion: any) => suggestion.name;

const renderSuggestion = (suggestion: any) => <div>{suggestion.name}</div>;

export const AutoSuggest = ({
  name,
  label,
  required,
  placeholder,
  setFieldValue,
  suggetions,
}: IAutoSuggestProps) => {
  const [suggestionState, setSuggestionState] = useState({
    value: '',
    suggestions: [],
  });

  const onChange = (_: any, { newValue }: any) => {
    setSuggestionState((prevState: any) => ({
      ...prevState,
      value: newValue,
    }));
    setFieldValue(name, newValue);
  };

  const onSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestionState((prevState: any) => ({
      ...prevState,
      suggestions: getSuggestions(value, suggetions),
    }));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionState((prevState: any) => ({
      ...prevState,
      suggestions: [],
    }));
  };

  const inputProps = {
    placeholder: placeholder,
    value: suggestionState.value,
    onChange: onChange,
  };

  return (
    <>
      <Label label={label} required={required} />
      <ReactAutosuggestWrapper>
        <Autosuggest
          suggestions={suggestionState.suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <Input name={name} type="hidden" />
      </ReactAutosuggestWrapper>
    </>
  );
};
