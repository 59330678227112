import React from 'react';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from '../error/error';
import { Label } from '../label/label';
import { useField } from 'formik';
import 'react-datepicker/dist/react-datepicker.min.css';
import { DateTimeProps } from './date-time.types';
import { FaCalendarAlt } from 'react-icons/fa';
import styled from 'styled-components';

const Wrapper = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }

  &.multi-dots {
    display: flex;
    padding: 10px;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 5px;
      padding: 0px 0 5px;
      width: 36px;
      border: 1px solid transparent;
    }

    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
      position: relative;
      color: #000;
      background-color: transparent;

      :before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
        top: 100%;
        left: 50%;
        margin: -10px 0 0 -4px;
      }

      &.react-datepicker__day--selected {
        border: 1px solid #30a58f;
      }
    }

    .react-datepicker {
      display: flex;
      flex-direction: column;
    }

    svg {
      display: none;
    }

    .hint-section {
      display: flex;
      justify-content: space-around;
      padding: 15px;
      background-color: #f0f0f0;

      .dot {
        display: flex;
        align-items: center;

        span {
          margin-right: 10px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #111111;
        }

        &.active {
          span {
            background-color: #30a58f;
          }
        }
      }
    }
  }
`;

export const DateTime = ({
  name,
  id,
  className,
  onChange,
  label,
  required,
  takeSpace,
  minDate,
  selectsRange,
  startDate,
  endDate,
  isClearable,
  withPortal,
  ...props
}: DateTimeProps) => {
  const [field, meta, helpers] = useField({ name: name || 'date' });
  const _id = id || name;
  const handleChange = (date: any) => {
    if (selectsRange) {
      helpers.setValue(date[0]);
    } else {
      helpers.setValue(date);
    }

    onChange && onChange(date);
  };

  return (
    <Wrapper className={className || 'form-group'}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      <div className="datepicker-wrap">
        <DatePicker
          id={_id}
          {...field}
          {...props}
          className={'primary-form-control'}
          selected={meta.value ? new Date(meta.value) : meta.value}
          onChange={handleChange}
          minDate={minDate}
          autoComplete="off"
          selectsRange={selectsRange}
          startDate={startDate}
          endDate={endDate}
          isClearable={isClearable}
          withPortal={withPortal}
        />
        <FaCalendarAlt />
      </div>
      <ErrorMessage meta={meta} />
    </Wrapper>
  );
};
