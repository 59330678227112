import styled from 'styled-components';

export const ReactAutosuggestWrapper = styled.div`
  input {
    height: 36px;
    border-radius: 4px;
    background: #fff;
    display: block;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    padding: 4px 15px;
    text-align: left;
    color: #000;
    border: 1px solid #e6e6e6 !important;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
`;
