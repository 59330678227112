import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import swal from 'sweetalert';
import * as yup from 'yup';
import {
  PageHeader,
  PaginatedTable,
  ActionButton,
  Divider,
  List,
  ListItem,
  ListItemAction,
  Menu,
  IconButton,
  Button,
  Modal,
  Loader,
} from '@lipihipi/ec-ui';
import { Form, Input, Label } from '@lipihipi/form';
import {
  IInterviewDocument,
  IInterviewListResponse,
} from '@lipihipi/client-sdk/dist/interview';
import { ICourseListResponse } from '@lipihipi/client-sdk/dist/course';

import { IInterviewListProps, IInterviewListParams } from './Interview.types';
import SearchableSelect from './searchable-select';
import { Select } from './select/select';

// const mapOptions = (values: any[]) => {
//   return values.map(value => ({ label: value.name, value: value._id }));
// };

export const getComponent = (onClick: any, name: string, flag: boolean) => {
  if (flag) {
    return (
      <IconButton onClick={onClick} className="primary-outine-button">
        <AiOutlinePlusCircle />
        {name}
      </IconButton>
    );
  }
  return null;
};

export const showErrorPopup = (error: any, setLoading: any) => {
  swal({
    title: error?.data?.message || 'Error',
    text:
      error?.data?.error?.code === 11000
        ? `Duplicate ${JSON.stringify(error?.data?.error?.keyValue)}`
        : error?.data?.message || 'Server error!',
    icon: 'error',
    dangerMode: false,
  });
  setLoading(false);
};

const Status = [
  { label: 'Publish', value: 'PUBLISH' },
  { label: 'UnPublish', value: 'UNPUBLISH' },
  { label: '', value: '' },
];

const MOBILE_REGEX = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
const mobile = yup.string().matches(MOBILE_REGEX, 'Invalid Mobile No.');
// .required('Mobile No. is required');
const email = yup.string().email('Please enter a valid email');
// .required('Email is required');

const validationSchema = yup.object().shape({
  name: yup.string(),
  email,
  mobile,
});

export default function ManageInterviewsList({
  statusUpdate,
  getInterviews,
  getCourses,
  onAdd,
  onEdit,
  title,
  breadCrumbs,
  currentModulePermission,
  getUserGroup,
  getUserByName,
  assignInterview,
  getCenters,
  updateInterview,
  role,
}: IInterviewListProps) {
  const itemsPerPage = 10;

  const [params, setParams] = React.useState<IInterviewListParams>({
    page: 1,
    populate: true,
    isRecent: true,
  });

  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState('');

  const [
    showAssignTestToStudentModal,
    setShowAssignTestToStudentModal,
  ] = useState<any>(null);

  const [assignPayload, setAssignPayload] = useState<{
    group?: '';
    user?: '';
    interview: '';
  }>({
    interview: '',
  });
  const [assignData, setAssignData] = useState<any>({
    users: [],
    groups: [],
    centers: [],
  });

  useEffect(() => {
    getUserGroup().then(({ data }: any) => {
      setAssignData((oldData: any) => ({ ...oldData, groups: data.groups }));
    });
    getCenters().then(({ data }: any) => {
      setAssignData((oldData: any) => ({ ...oldData, centers: data.centers }));
    });
  }, []);

  const handlePublish = (interviewId: string, status: boolean) => {
    setLoading(true);

    statusUpdate(interviewId, { status: status ? 'PUBLISH' : 'INIT' })
      .then((response: any) => {
        if (response.status === 200) {
          swal({
            title: 'Success',
            text: `Interview ${status ? 'Published' : 'Unpublished'}`,
            icon: 'success',
          }).then(() => {
            setLoading(false);
            getData();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
          }).then(() => {
            setLoading(false);
          });
        }
      })
      .catch(() => {
        console.log('Server Error');
      });
  };
  const handleSearch = (values: IInterviewListParams) => {
    setParams({
      page: 1,
      q: values.q,
      populate: true,
      course: values.course,
      status: values.status,
    });
  };

  const [interviews, setInterviews] = React.useState<IInterviewListResponse>({
    totalItems: 0,
    interviews: [],
  });

  const [courses, setCourses] = React.useState<ICourseListResponse>({
    totalItems: 0,
    courses: [],
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    getCourses({ all: true })
      .then(response => {
        setCourses(response.data);
      })
      .catch(() => {});
  }, []);

  const getData = () => {
    setIsLoading(true);
    getInterviews(params).then(response => {
      setIsLoading(false);
      setInterviews(response.data);
    });
  };

  React.useEffect(getData, [params]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    if (showAssignTestToStudentModal === 'center') {
      const { status } = await updateInterview({
        _id: values.interview,
        center: values.center,
      });
      apiStatus(status, 'Interview Assigned.');

      return;
    }
    try {
      const { status } = await assignInterview(values);
      apiStatus(status, 'Interview Assigned.');
    } catch (error) {
      showErrorPopup(error, setLoading);
    }
  };

  const apiStatus = (status: number, text: string) => {
    if (status === 200) {
      swal({
        title: 'Success',
        text,
        icon: 'success',
      }).then(() => {
        setShowAssignTestToStudentModal(null);
        setLoading(false);
      });
    } else {
      swal({
        title: 'Error',
        text: 'Server Error!',
        icon: 'error',
      }).then(() => {
        setShowAssignTestToStudentModal(null);
        setLoading(false);
      });
    }
  };

  return (
    // Manage Interview Bundle
    <>
      {isLoading && <Loader />}
      <section className="main-structure">
        <PageHeader
          title={title || 'Manage IntAna'}
          breadCrumbs={
            breadCrumbs || [
              { title: 'Home', link: '/dashboard' },
              { title: 'Manage IntAna' },
            ]
          }
          component={getComponent(
            onAdd,
            'Add an IntAna',
            currentModulePermission?.includes('create')
          )}
        />

        <Form
          initialValues={params}
          onSubmit={handleSearch}
          render={({ submitForm }: any) => (
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <input
                    //  TODO Move insline style into scss,
                    type="text"
                    name="q"
                    placeholder="Type query and press enter"
                    className="primary-form-control"
                    onKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        handleSearch({
                          ...params,
                          q: event.target.value.trim(),
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <Select
                  id="status"
                  name="status"
                  placeholder="Status"
                  onChange={submitForm}
                  options={Status}
                />
              </div>
              <div className="col-3">
                <Select
                  id="course"
                  name="course"
                  placeholder="Course"
                  onChange={submitForm}
                  options={[{ label: 'ALL', value: '' }, ...courses?.courses]}
                />
              </div>
            </div>
          )}
        />

        <PaginatedTable
          onPageChange={page => {
            setParams({ ...params, page: page });
          }}
          totalItems={interviews.totalItems}
          currentPage={params.page || 1}
          itemsPerPage={itemsPerPage}
          columns={[
            {
              dataRenderer: (data: IInterviewDocument) => (
                <div className="primary-text">{data.interviewId}</div>
              ),
              title: 'IntAna Id',
              width: '200px',
            },
            {
              dataRenderer: (data: IInterviewDocument) => (
                <div className="primary-text">{data.name}</div>
              ),
              isSortable: true,
              title: 'Name',
              width: 'calc(100% - (200px + 200px + 100px + 100px + 70px))',
            },
            {
              dataRenderer: (data: any) => (
                <div className="primary-text">{data.status}</div>
              ),
              title: 'Status',
              width: '100px',
            },
            {
              dataRenderer: (data: any) => (
                <div className="primary-text">{data.course?.name}</div>
              ),
              title: 'Course',
              width: '100px',
            },
            {
              dataRenderer: (data: IInterviewDocument) => (
                <div className="primary-text">{data.price}</div>
              ),
              title: 'Price',
              width: '100px',
            },
            {
              dataRenderer: (data: IInterviewDocument) => (
                <div className="primary-text">{data.noOfLinks}</div>
              ),
              title: 'No of Links',
              width: '100px',
              // },{
              // 	dataRenderer: (data: IInterviewDocument) => <div style={{ color: "#b5b5b5", textAlign: "right", fontSize: "12px" }}>
              // 		{data.updatedAt? `Last updated at ${moment(data.updatedAt).format('lll')}` : ''}
              // 	</div>
              //  TODO Move insline style into scss
            },
            {
              dataRenderer: (interview: any) => (
                <List>
                  <ListItem className="action-button">
                    <ListItemAction>
                      <ActionButton>
                        <BsThreeDotsVertical />
                      </ActionButton>
                      <Menu>
                        {role === 'superadmin' && (
                          <li
                            onClick={() => {
                              setShowAssignTestToStudentModal('center');
                              setAssignPayload((oldData: any) => ({
                                ...oldData,
                                interview: interview?._id,
                                center: interview?.center,
                              }));
                            }}
                          >
                            Assign To Centers
                          </li>
                        )}
                        <li
                          onClick={() => {
                            setShowAssignTestToStudentModal('candidate');
                            setAssignPayload((oldData: any) => ({
                              ...oldData,
                              interview: interview?._id,
                            }));
                          }}
                        >
                          Assign To Candidate
                        </li>
                        {currentModulePermission?.includes('update') &&
                          role === 'superadmin' && (
                            <li onClick={() => onEdit(interview._id)}>Edit</li>
                          )}
                        <li
                          onClick={() =>
                            handlePublish(
                              interview._id,
                              interview?.status === 'INIT'
                            )
                          }
                        >
                          {interview.status === 'PUBLISH'
                            ? 'UnPublish'
                            : 'Publish'}
                        </li>
                      </Menu>
                    </ListItemAction>
                  </ListItem>
                  <Divider />
                </List>
              ),
              width: '70px',
            },
          ]}
          data={interviews.interviews}
        />

        {showAssignTestToStudentModal !== '' && (
          <Modal
            isOpen={
              showAssignTestToStudentModal === 'candidate' ||
              showAssignTestToStudentModal === 'center'
            }
            onRequestClose={() => setShowAssignTestToStudentModal('')}
          >
            <Form
              initialValues={{ ...assignPayload }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <header
                className="text-center mb-4"
                style={{ minWidth: '320px' }}
              >
                <Label
                  id="topic_header"
                  label={
                    showAssignTestToStudentModal === 'candidate'
                      ? 'Assign Interview To Candidate'
                      : 'Assign Interview To Center'
                  }
                  className="text-dark font-weight-bolder"
                />
                <br />
                <hr />
              </header>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <Select
                    id={
                      showAssignTestToStudentModal === 'candidate'
                        ? 'group'
                        : 'center'
                    }
                    name={
                      showAssignTestToStudentModal === 'candidate'
                        ? 'group'
                        : 'center'
                    }
                    placeholder={
                      showAssignTestToStudentModal === 'candidate'
                        ? 'Select Group'
                        : 'Select a Center'
                    }
                    options={
                      showAssignTestToStudentModal === 'candidate'
                        ? assignData?.groups
                        : assignData?.centers
                    }
                    isMulti={showAssignTestToStudentModal === 'center'}
                    onChange={() => {}}
                  />
                  {showAssignTestToStudentModal === 'candidate' && (
                    <>
                      <p>OR</p>
                      <div className="form-group">
                        <SearchableSelect
                          getUserByName={getUserByName}
                          name="user"
                        />
                        <br />
                        <hr />
                        <Label
                          id="new_user"
                          label="For new user"
                          className="text-dark font-weight-bolder"
                        />
                        <Input name="name" id="name" placeholder="Name" />
                        <Input name="email" id="email" placeholder="Email" />
                        <Input name="mobile" id="mobile" placeholder="Mobile" />
                      </div>
                    </>
                  )}
                  {/* {error && <p>{error}</p>} */}
                  <footer className="text-center mb-4">
                    <hr />
                    <br />
                    <Button className="px-5" shape="primary" type="submit">
                      SAVE
                    </Button>
                    <Button
                      className="px-5"
                      shape="primary"
                      onClick={() => setShowAssignTestToStudentModal('')}
                    >
                      Close
                    </Button>
                  </footer>
                </>
              )}
            </Form>
          </Modal>
        )}
      </section>
    </>
  );
}
