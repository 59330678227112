import EducrackAPI from '@lipihipi/client-sdk';
import React, { useState, useEffect } from 'react';
import { commonApiError } from '../subscriber-report';
import { Accordion, Card } from 'react-bootstrap';
import { ImFileText2 } from 'react-icons/im';

const SubscriberPyschometricTests = ({ _id, onPsychometricReportClick }: any) => {
  const [tests, setTests] = useState<any>({
    totalItems: 0,
    tests: [],
  });
  const [visible, setVisible] = useState<any>(10);

  const loadMore = () => {
    setVisible(visible + 10);
  };

  useEffect(() => {
    EducrackAPI.psychometricUserTest
      .list({ user: _id, populate: true })
      .then(({ data }: any) => {
        setTests(data);
      })
      .catch((error: any) => {
        commonApiError(error);
      });
  }, []);

  return (
    <div className="lesson-wrapping">
      <div className="batch-accordion test-accordian">
      {tests?.tests.length > 0 ? (
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <p className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items--md-center pr-4">
                <span style={{ fontSize: '15px' }}>Psychometric Test's list</span>
              </p>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Card.Body style={{ padding: '0 17px 17px' }}>
                {tests?.tests?.slice(0, visible)?.map((test: any) => {
                  const finishedTests = test?.attempts?.filter(
                    (v: any) => v.status === 'FINISHED'
                  );
                  const latestAttempt =
                    finishedTests?.length > 0 &&
                    finishedTests[finishedTests?.length - 1];
                  return (
                    <>
                        <div className="my-progress mb-4 pr-5">
                          <div className="my-progress--name" style={{flexGrow: 1}}>
                            <span>
                              <ImFileText2 />
                            </span>
                            <p>{test?.name || ''}</p>
                          </div>
                          <div className="my-progress--action">
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                onPsychometricReportClick(latestAttempt?._id)
                              }
                            >
                              View Details
                            </a>
                          </div>
                        </div>
                      </>
                  );
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : (
        <p>No Data Available</p>
        )}
        </div>
      {visible < tests?.length && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={loadMore}>View More</p>
        </div>
      )}
      {visible >= tests?.length && visible > 10 && (
        <div
          className="text-center"
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            cursor: 'pointer',
            marginTop: '20px',
            color: '#00a689',
          }}
        >
          <p onClick={() => setVisible(10)}>View less</p>
        </div>
      )}
    </div>
  );
};

export default SubscriberPyschometricTests;
