import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { Form, Select, CheckBox, Input } from '@lipihipi/form';
import { Button, Modal, Table } from '@lipihipi/ec-ui';

const mapOptions = (values: any[]) => {
  return values.map(value => ({
    label: value?.name || '',
    value: value?._id || '',
  }));
};

export const SelectLibraryModal = ({
  isOpen,
  onRequestClose,
  assignment,
  sectionSubjects,
  getLibrary,
  setSelected,
  assignedLibrary,
}: any) => {
  const [params, setParams] = useState<any>({
    populate: true,
    page: 1,
    course: assignment?.course?._id,
  });
  const [library, setLibrary] = useState<any>({
    totalItems: 0,
    courseware: [],
  });
  const [selectedLibrary, setSelectedLibrary] = useState<any>([]);
  const [subjects, setSubjects] = React.useState<any>([]);

  useEffect(() => {
    getLibrary({ ...params, all: true, populate: true }).then((res: any) => {
      setLibrary(res.data);
    });
  }, [params]);

  useEffect(() => {
    const _subjects = sectionSubjects.map((item: any) => {
      return item.subject;
    });
    setSubjects(_subjects);
  }, [sectionSubjects]);

  const handleSearch = (values: any) => {
    setParams((prev: any) => ({
      ...prev,
      q: values.q,
      subject: values.subject,
    }));
  };

  const handleCheckbox = (isChecked: boolean, value: any) => {
    if (isChecked) {
      setSelectedLibrary([...selectedLibrary, value]);
    } else {
      setSelectedLibrary(
        selectedLibrary.filter((test: any) => test._id !== value._id)
      );
    }
  };

  const getCheckStatus = (_id: any) => {
    const data = assignedLibrary.filter((item: any) => item._id === _id);
    return !!data.length;
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="question-bank">
        <div>
          <div style={{ marginBottom: '20px' }}>
            <h3>You are assigning to: {assignment?.name || '--'}</h3>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <div className="row">
              <div className="col-12 col-md-12">
                <Form
                  initialValues={{
                    q: '',
                    subject: '',
                  }}
                  onSubmit={handleSearch}
                  render={({ submitForm, values }: any) => {
                    console.log('values', values);

                    return (
                      <>
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <Input
                              prefix={<MdSearch />}
                              id="q"
                              name="q"
                              label="Library Name"
                              placeholder="Enter here to search for library name"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <Select
                              id="subject"
                              name="subject"
                              label="Subjects"
                              placeholder="Select subjects"
                              options={[
                                { label: 'ALL', value: '' },
                                ...mapOptions(
                                  subjects !== undefined ? subjects : []
                                ),
                              ]}
                              onChange={(_: any) => {
                                submitForm();
                              }}
                            />
                          </div>

                          <Button
                            shape="primary"
                            className="d-none"
                            type="submit"
                          >
                            Search
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ minHeight: '100px' }}>
          <Table
            data={library.courseware}
            columns={[
              {
                dataRenderer: (data: any) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Form
                        initialValues={{
                          [data._id]: getCheckStatus(data._id),
                        }}
                      >
                        <CheckBox
                          id={data._id}
                          name={data._id}
                          onChange={(event: any) => {
                            handleCheckbox(event.target.checked, data);
                          }}
                        />
                      </Form>
                      {data.subChapterName}
                    </div>
                  </>
                ),
                title: 'Item name',
              },
              {
                dataRenderer: (data: any) => (
                  <>{data?.asset?.split('.').pop()}</>
                ),
                title: 'Type',
              },
              {
                dataRenderer: (data: any) => <>{data?.subject?.name}</>,
                title: 'Subject',
              },
            ]}
          />
        </div>

        <div className="question-bank--footer">
          <Button shape="secondary" onClick={onRequestClose}>
            Back
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            shape="primary"
            onClick={() => {
              if (selectedLibrary.length > 0) {
                setSelected(selectedLibrary);
                onRequestClose();
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};
