import React from 'react';
// import Styled from 'styled-components';
import { SearchBarProps } from './search-bar.types';
import { AiOutlineSearch } from 'react-icons/ai';

// const CustomSearchWrapper = Styled.div`
//     &:focus-within{
//         outline: none;
//         border: 1px solid #239F87;
//     }
// `;

// const CustomSearchBar = Styled.input`
//     border: none;
//     box-shadow: none;
//     &:focus{
//         box-shadow: none;
//     }
//     &::placeholder{
//         color: #919191;
//         font-size: 16px;
//         font-weight: normal;
//     }
// `;

// const Label = Styled.label`
//     color: #434544;
//     font-size: 13px;
//     font-weight: 500;
//     text-transform: uppercase;
// `;

export const SearchBar = ({
  id,
  name,
  label,
  placeholder,
  ...rest
}: SearchBarProps) => {
  return (
    <div className="form-group">
      <label className={`${label ? 'd-block' : 'd-none'}`} htmlFor={id}>
        {label}
      </label>
      <div className="input-group border-0 rounded">
        <div className="input-group-prepend">
          <div className="input-group-text border-0">
            <AiOutlineSearch color="#acb0b5" />
          </div>
        </div>
        <input
          type="text"
          className="primary-form-control form-control-prepended"
          id={id}
          name={name || id}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </div>
  );
};
