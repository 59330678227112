export const truncateFileName = (fileName: string, length: number) => {
  const ext = fileName?.substr(fileName.lastIndexOf('.') + 1);
  const name = fileName?.substr(0, fileName.lastIndexOf('.'));

  if (name != undefined) {
    if (name.length <= length) {
      return fileName;
    }
  
  return `${name.slice(0, length)}...${ext}`;
  }
  return '';
};
