import React, { useEffect, useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import {
  Button,
  PageHeader,
  PaginatedTable,
  ActionButton,
  ListItemAction,
  Menu,
} from '@lipihipi/ec-ui';
import { Form, Input, Select } from '@lipihipi/form';
import { MdSearch } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';

const mapOptions = (values: any[]) => {
  return values?.map(value => ({ label: value.name, value: value._id }));
};

const mapOptionsEducator = (values: any[]) => {
  return values?.map(value => ({ label: value.name, value: value._id }));
};

export const AssignListing = ({
  title,
  breadCrumbs,
  onEdit,
  assignList,
  onClickAssignNow,
  getCourses,
  getEducator,
  getUser,
  updateAssignment,
}: any) => {
  const [params, setParams] = useState<{
    populate?: boolean;
    page?: number;
    q?: string;
    course?: string;
    type?: string;
    addedBy?: string;
  }>({
    populate: true,
    page: 1,
  });

  const [user, setUser] = useState<any>({});
  const [courses, setCourses] = useState<any>({
    totalItems: 0,
    courses: [],
  });
  const [educators, setEducator] = useState<any>({
    totalItems: 0,
    educators: [],
  });
  const [assignments, setAssignments] = useState<any>({
    totalItems: 0,
    assignments: [],
  });

  useEffect(() => {
    _getUser();
    _getCourses();
    _getEducator();
  }, []);

  useEffect(() => {
    _assignList();
  }, [params]);

  const _getUser = () => {
    getUser({ all: true }).then((response: any) => {
      const resp = Array.isArray(response.data)
        ? response.data
        : [response.data];
      setUser(resp);
    });
  };

  const _getCourses = () => {
    getCourses({ all: true }).then((response: any) => {
      setCourses(response.data);
    });
  };

  const _getEducator = () => {
    getEducator({ all: true }).then((response: any) => {
      setEducator(response.data);
    });
  };

  const _assignList = () => {
    assignList({ ...params })
      .then((res: any) => {
        setAssignments(res.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleSearch = (values: any) => {
    setParams((prev: any) => ({
      ...prev,
      populate: true,
      page: 1,
      q: values.q,
      course: values.course,
      type: values.type,
      addedBy: values.addedBy,
    }));
  };

  const handleDisabled = (data: any) => {
    const payload = {
      _id: data._id,
      active: !data.active,
    };

    updateAssignment(payload)
      .then(({ status }: any) => {
        if (status === 200) {
          swal({
            title: 'Success',
            text: 'Assignment Updated',
            icon: 'success',
            dangerMode: false,
          }).then(() => {
            _assignList();
          });
        } else {
          swal({
            title: 'Error',
            text: 'Server Error!',
            icon: 'error',
            dangerMode: true,
          });
        }
      })
      .catch(() => {
        swal({
          title: 'Error',
          text: 'Server Error!',
          icon: 'error',
          dangerMode: true,
        });
      });
  };

  const tableColumns = [
    {
      dataRenderer: (data: any) => (
        <>
          <div className="primary-text d-flex align-items-center">
            {data?.assign_type === 'TEST' && (
              <div
                className="assignment-type-bullets test mr-2"
                style={{ flexShrink: 0 }}
              >
                Test
              </div>
            )}
            {data?.assign_type === 'GROUP' && (
              <div
                className="assignment-type-bullets group mr-2"
                style={{ flexShrink: 0 }}
              >
                group
              </div>
            )}
            {data?.assign_type === 'BATCH' && (
              <div
                className="assignment-type-bullets batch mr-2"
                style={{ flexShrink: 0 }}
              >
                batch
              </div>
            )}
            {data?.name}
          </div>
        </>
      ),
      title: 'Assignment Name',
      width: 'calc(100% - (150px + 150px + 150px + 210px + 70px))',
    },
    {
      dataRenderer: (data: any) => (
        <>
          <div className="primary-text">{data?.course?.name}</div>
        </>
      ),
      title: 'Course',
      width: '150px',
    },
    {
      dataRenderer: (data: any) => (
        <>
          <div className="primary-text">{data?.addedBy?.name}</div>
        </>
      ),
      title: 'Assigned by',
      width: '150px',
    },
    {
      dataRenderer: (data: any) => (
        <>
          <div className="primary-text">
            {data?.group?.name || data?.batch?.name}
          </div>
        </>
      ),
      title: 'Assigned to',
      width: '150px',
    },
    {
      dataRenderer: (data: any) => (
        <>
          <div className="primary-text">{`Starts on ${moment(
            data?.startTime
          ).format('DD MMMM YYYY h:mma')}`}</div>
          <div className="primary-text">{`Ends on ${moment(
            data?.endTime
          ).format('DD MMMM YYYY h:mma')}`}</div>
        </>
      ),
      title: '',
      width: '210px',
    },
    {
      dataRenderer: (data: any) => (
        <>
          <ListItemAction className="action-button">
            <ActionButton>
              <BsThreeDotsVertical />
            </ActionButton>
            <Menu>
              <li onClick={() => onEdit(data?._id)}>Edit</li>
              <li
                onClick={() => {
                  handleDisabled(data);
                }}
              >
                {data.active ? 'Inactive' : 'Active'}
              </li>
            </Menu>
          </ListItemAction>
        </>
      ),
      title: '',
      width: '70px',
    },
  ];

  return (
    <section className="main-structure">
      <PageHeader
        title={title || 'Manage Assignments'}
        breadCrumbs={
          breadCrumbs || [
            { title: 'Home', link: '/dashboard' },
            { title: 'Manage Assignments', link: '/' },
          ]
        }
        component={
          <Button shape={'primary'} onClick={onClickAssignNow}>
            Assign now
          </Button>
        }
      />

      <Form
        initialValues={params}
        onSubmit={handleSearch}
        render={({ submitForm }: any) => (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <Input
                  prefix={<MdSearch />}
                  id="q"
                  name="q"
                  placeholder="Enter here to search for assignment name"
                />
              </div>
              <div className="col-md-3 col-lg-3">
                <Select
                  id="course"
                  name="course"
                  label=""
                  placeholder="Course"
                  options={[
                    { label: 'ALL', value: '' },
                    ...mapOptions(courses.courses),
                  ]}
                  onChange={submitForm}
                />
              </div>
              <div className="col-md-3 col-lg-2">
                <Select
                  id="type"
                  name="type"
                  placeholder="Select Type"
                  options={[
                    { label: 'Batch', value: 'BATCH' },
                    { label: 'Group', value: 'GROUP' },
                    { label: 'No Group', value: 'NOGROUP' },
                  ]}
                  onChange={submitForm}
                />
              </div>
              {user.role === 'superadmin' || user.role === 'admin' ? (
                <div className="col-md-3 col-lg-3">
                  <Select
                    id="addedBy"
                    name="addedBy"
                    placeholder="Select Added by"
                    options={[
                      { label: 'ALL', value: '' },
                      ...mapOptionsEducator(
                        educators.educators !== undefined
                          ? educators.educators
                          : []
                      ),
                    ]}
                    onChange={submitForm}
                  />
                </div>
              ) : (
                ''
              )}

              <Button shape={'primary'} className="d-none" type={'submit'}>
                Search
              </Button>
            </div>
          </>
        )}
      />

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group d-flex align-items-center justify-content-end">
            <span>
              <span
                style={{
                  display: 'inline-block',
                  width: '15px',
                  height: '15px',
                  verticalAlign: 'middle',
                  borderRadius: '50%',
                  backgroundColor: '#BEAB00',
                }}
              ></span>
              <span
                style={{
                  margin: '0 20px 0 10px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                Test Bundle
              </span>
            </span>
            <span>
              <span
                style={{
                  display: 'inline-block',
                  width: '15px',
                  height: '15px',
                  verticalAlign: 'middle',
                  borderRadius: '50%',
                  backgroundColor: '#00B8BE',
                }}
              ></span>
              <span
                style={{
                  margin: '0 20px 0 10px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                Group
              </span>
            </span>
            <span>
              <span
                style={{
                  display: 'inline-block',
                  width: '15px',
                  height: '15px',
                  verticalAlign: 'middle',
                  borderRadius: '50%',
                  backgroundColor: '#00BE4C',
                }}
              ></span>
              <span
                style={{
                  margin: '0 20px 0 10px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                Batch
              </span>
            </span>
          </div>
        </div>
      </div>

      <PaginatedTable
        columns={tableColumns}
        data={assignments.assignments}
        totalItems={assignments.totalItems}
        itemsPerPage={10}
        currentPage={params.page || 1}
        onPageChange={page => setParams({ ...params, page })}
      />
    </section>
  );
};

export default AssignListing;
