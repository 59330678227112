import React, { useEffect } from 'react';
import Styled from 'styled-components';
import { useField } from 'formik';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Label } from '../label/label';
import { ErrorMessage } from '../error/error';
import { ImageCropperProps } from './image-cropper.types';
import { ImagePreview } from './image-preview';

const ImageCropperWrapper = Styled.div`
  padding: 10px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #e6e6e6;
`;

const UploadBtn = Styled.button`
  padding: 5px 10px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #e6e6e6;
`;

export const ImageCropper = ({
  id,
  name,
  previewPic,
  label,
  className,
  style,
  required,
  width,
  ratio,
  accept,
  splitView,
  getAssetUrl,
  uploadFile,
  deleteFile,
  getUploadFile,
  getDeleteFile,
}: ImageCropperProps) => {
  let timerId: any = null;
  const [field, meta, helpers] = useField({ name });
  const showError = meta.error;

  const [state, setState] = React.useState<any>({
    src: null,
    crop: {
      unit: '%',
      width: width || 30,
      aspect: ratio || 16 / 9,
    },
    croppedImageUrl: previewPic ? getAssetUrl(previewPic) : '',
    fileName: null,
    uploadedFile: previewPic || '',
  });

  const [fileUploadStatus, setFileUploadStatus] = React.useState<string>('');

  const imageRef = React.useRef(null);

  useEffect(() => {
    return reset;
  }, []);

  useEffect(() => {
    setState((prevState: any) => ({
      ...prevState,
      croppedImageUrl: previewPic ? getAssetUrl(previewPic) : '',
      uploadedFile: previewPic,
    }));
    setFileUploadStatus('loaded');
  }, [previewPic]);

  const reset = () => {
    clearTimeout(timerId);
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const fileName = e.target.files[0].name;
      reader.addEventListener('load', () => {
        setState((prevState: any) => ({
          ...prevState,
          src: reader.result,
          fileName,
        }));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image: any) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop: any) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop: any) => {
    setState((prevState: any) => ({
      ...prevState,
      crop,
    }));
    setFileUploadStatus('');
  };

  const makeClientCrop = async (crop: any) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        'crop-image.png'
      );

      setState((prevState: any) => ({
        ...prevState,
        croppedImageUrl,
      }));
    }
  };

  const getCroppedImg = async (image: any, crop: any, fileName: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          console.error('Canvas is empty');
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;

        let fileUrl = '';
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, 'image/png');
    });
  };

  const onUploadFileHandler = async () => {
    let blobUrl = await fetch(state?.croppedImageUrl).then(r => r.blob());
    const file = new File([blobUrl], `${state?.fileName}`);

    setFileUploadStatus('loading');

    uploadFile({ file })
      .then((res: any) => {
        setFileUploadStatus('loaded');

        setState((prevState: any) => ({
          ...prevState,
          uploadedFile: res?.data?.key,
        }));

        helpers.setValue(res?.data?.key);

        if (getUploadFile) {
          getUploadFile(res?.data?.key);
        }
      })
      .catch(() => {
        setFileUploadStatus('');
      });
  };

  const handleDelete = (value: any) => {
    console.log(value);
    setState((prevState: any) => ({
      ...prevState,
      croppedImageUrl: undefined,
    }));
    setFileUploadStatus('');
    helpers.setValue(undefined);

    if (deleteFile && state.uploadedFile) {
      deleteFile(state.uploadedFile);
      setState((prevState: any) => ({
        ...prevState,
        uploadedFile: '',
      }));

      if (getDeleteFile) {
        getDeleteFile(state.uploadedFile);
      }
    }
  };

  return (
    <div className={className} style={style}>
      <Label label={label} required={required} />
      <ImageCropperWrapper
        className={`${showError && 'is-invalid'}`}
        {...field}
      >
        <div className="row">
          <div className={`${splitView ? 'col-md-12' : 'col-md-6'}`}>
            <div>
              <input
                id={id}
                name={name}
                type="file"
                accept={accept.join(',')}
                onChange={onSelectFile}
              />
              <ErrorMessage meta={meta} />
            </div>

            {state?.src && (
              <div style={{ paddingTop: '10px' }}>
                <ReactCrop
                  src={state?.src}
                  crop={state?.crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                />
              </div>
            )}
          </div>
          <div className={`${splitView ? 'col-md-12' : 'col-md-6'}`}>
            {state?.croppedImageUrl ? (
              <>
                <div>
                  <Label
                    label={`Image Preview ${
                      state.src
                        ? `${parseInt(state.crop.width)} x ${parseInt(
                            state.crop.height
                          )}`
                        : ''
                    }`}
                  />
                </div>

                <div>
                  <ImagePreview
                    file={state?.croppedImageUrl}
                    onDeletePreview={handleDelete}
                  />
                </div>

                {fileUploadStatus !== 'loaded' && (
                  <div style={{ paddingTop: '15px' }}>
                    <UploadBtn
                      id={id}
                      onClick={onUploadFileHandler}
                      type="button"
                      disabled={fileUploadStatus === 'loading'}
                    >
                      {fileUploadStatus === 'loading'
                        ? 'Uploading...'
                        : 'Upload'}
                    </UploadBtn>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </ImageCropperWrapper>
    </div>
  );
};
